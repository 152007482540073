import { useEffect, useRef, useState } from 'react';
import { Page, Document, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFProps {
    pdfLink: string;
}

const PDFViewer = (props: PDFProps) => {
    const { pdfLink } = props;
    const [numPages, setNumPages] = useState<Number>();
    const parentRef = useRef<HTMLDivElement>(null);

    const [width, setWidth] = useState(0);

    const handleWindowSizeChange = () => {
        if (parentRef.current) {
            setWidth(parentRef.current.offsetWidth);
        }
    };
    const onDocumentLoadSuccess = ({numPages}: {numPages: number}) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        setWidth(parentRef.current ? parentRef.current.offsetWidth : 0);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);


    return (
        <div style={{ height: '100vh' }} ref={parentRef}>
            <Document 
                onLoadSuccess={onDocumentLoadSuccess}
                file={{
                    url: 'https://storage.googleapis.com/menu_pdfs/' + pdfLink +'.pdf?ignoreCache=1'
                }}
                onLoadError={console.error}>
                    {Array.apply(null, Array(numPages))
                        .map((x,i) => i + 1)
                        .map((page) => (
                            <Page key={page} width={width} pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false}/>
                        ))}
            </Document>
        </div>
        
    );
}

export default PDFViewer;
