
import { Route, Routes } from 'react-router-dom';
import PDFViewer from './PDFViewer';

const LADRoutes = () => {
    return (
        <Routes>
            <Route path='/DugoutClub' element={<PDFViewer pdfLink='dugout_club' />} />
            <Route path='/BaselineClub' element={<PDFViewer pdfLink='baseline_club' />} />
            <Route path="/HomerunSeats" element={<PDFViewer pdfLink='homerun_seats' />} />
            <Route path='/ExecutiveClub' element={<PDFViewer pdfLink='executive_club' />} />
            <Route path='/GoldGloveBar' element={<PDFViewer pdfLink='gold_glove_bar' />} />
        </Routes>
    );
};

export default LADRoutes;